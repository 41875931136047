export default {
  secondStepTitle: {
    id: 'cookies_modal_title',
    defaultMessage: 'Configure',
  },
  secondStepDescription: {
    id: 'cookies_modal_page_description',
    defaultMessage:
      'Set your preferences on cookies and data confidentiality. See below for the specific uses.',
  },
  paragraph: {
    id: 'cookies_modal_content',
    defaultMessage:
      'We and our partners also use cookies that allow us to measure traffic and show you personalized content and ads rather than basic shiz. Think sea salt chocolate chunk vs. oatmeal raisin.',
  },
  cookiesUsage: {
    id: 'cookies_modal_cookies_usage_and_legal_links',
    defaultMessage:
      'You can change your preferences later by clicking “Cookies” at the bottom of any page. Tell me more about those {cookiesLink, html}, {legalMentionsLink, html} and {dataProtectionLink, html}.',
  },
  cookies: {
    id: 'cookies_modal_cookies_terms_link',
    defaultMessage: 'Cookies',
  },
  legalMentions: {
    id: 'cookies_modal_legal_mentions_link',
    defaultMessage: 'Legal notices',
  },
  dataProtection: {
    id: 'cookies_modal_data_protection_link',
    defaultMessage: 'Data protection',
  },
  buttonSettings: {
    id: 'cookies_modal_settings',
    defaultMessage: 'Change settings',
  },
  buttonAccept: {
    id: 'cookies_modal_accept',
    defaultMessage: "I'm cool with that",
  },
  buttonRefuse: {
    id: 'cookies_modal_refuse',
    defaultMessage: 'I refuse your cookies',
  },
  save: {
    id: 'cookies_modal_save',
    defaultMessage: 'Save',
  },
}
