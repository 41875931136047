
import { mapGetters } from 'vuex'

import { hideBanner, showBanner } from './appsflyer'
import { DO_NOT_DISTURB_ROUTES } from './constants'
import { canDisplayBanner } from './helpers'

export default {
  computed: {
    ...mapGetters({
      isNavigationOpen: 'isNavigationOpened',
      isCookieModalOpen: 'gdpr/cookies/isOpen',
      isBannerOpen: 'appsflyer/isBannerOpen',
    }),
  },

  watch: {
    $route() {
      this.evalBannerDisplay()
    },

    isNavigationOpen() {
      this.evalBannerDisplay()
    },

    isCookieModalOpen() {
      this.evalBannerDisplay()
    },
  },

  mounted() {
    this.evalBannerDisplay()
  },

  methods: {
    evalBannerDisplay() {
      const shouldBannerBeDisplayed = canDisplayBanner({
        isCookieModalOpen: this.isCookieModalOpen,
        isNavigationOpen: this.isNavigationOpen,
        currentRoute: this.$route.name,
        doNotDisturbRoutesCollections: DO_NOT_DISTURB_ROUTES,
      })

      // Could happen during a layout switch.
      // Trying to open the banner twice creates
      // a side effect on the header's position.
      if (shouldBannerBeDisplayed && this.isBannerOpen) {
        return
      }

      const updateBannerDisplay = shouldBannerBeDisplayed
        ? showBanner
        : hideBanner

      updateBannerDisplay()

      this.$store.dispatch('appsflyer/setIsBannerOpen', shouldBannerBeDisplayed)
    },
  },
}
