
import { RevButton } from '@ds/components/Button'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'
import { mapGetters } from 'vuex'

import { MARKETPLACES } from '@config/constants'
import FormattedMessage from '@i18n/components/FormattedMessage'
import { ROUTES } from '@router'
import { trackClick } from '@tracking/events'

import { COOKIES_TRACKING_NAME, LEGAL_PAGE_NAME } from '../../constants'

import translations from './CookiesModal.translations'

export default {
  components: {
    RevButton,
    RevLink,
    RevIllustration,
    FormattedMessage,
  },

  props: {
    marketplace: {
      type: String,
      required: true,
    },
    zone: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      windowHeight: 0,
      accepted: false,
    }
  },

  computed: {
    ...mapGetters({
      country: 'config/country',
    }),
    translations: () => translations,
    ROUTES: () => ROUTES,
    LEGAL_PAGE_NAME: () => LEGAL_PAGE_NAME,
    COOKIES_TRACKING_NAME: () => COOKIES_TRACKING_NAME,
    displayRefuseAllButton() {
      return this.marketplace !== MARKETPLACES.NA
    },
    cookieImage() {
      return this.$static('/img/gdpr/cookie.png')
    },
  },

  mounted() {
    this.windowHeight = window.innerHeight
  },

  methods: {
    handleClickAccept() {
      if (!this.loading) {
        this.accepted = true

        trackClick({ zone: this.zone, name: COOKIES_TRACKING_NAME.ACCEPT })
        this.$emit('accept')
      }
    },
    handleClickRefuse() {
      if (!this.loading) {
        trackClick({ zone: this.zone, name: COOKIES_TRACKING_NAME.REFUSE })
        this.$emit('refuse')
      }
    },
    handleClickParams() {
      trackClick({ zone: this.zone, name: COOKIES_TRACKING_NAME.SETTINGS })
      this.$emit('params')
    },
    handleClickTerms(name) {
      trackClick({ zone: this.zone, name })
    },
  },
}
